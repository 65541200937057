import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import type { Search, ModelFind } from '~/models'
import { aOrganization } from '~/atoms'
import type { ApiResponse } from './utils'

type FetchSearchesResponse = ApiResponse & {
  searches: Search[]
  count: number
  nextOffset: number
}

async function fetchSearches({
  find,
  sort,
  project,
  limit,
  offset,
  signal,
}: {
  find: ModelFind
  sort?: { [key: string]: number }
  project?: {}
  limit?: number
  offset?: number
  signal?: AbortSignal
}): Promise<FetchSearchesResponse> {
  const response = await fetch('/api/searches', {
    body: JSON.stringify({
      find,
      sort,
      project,
      limit,
      offset,
    }),
    method: 'POST',
    signal,
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading search', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useSearchesQuery({
  key,
  find,
  sort,
  keepPreviousData = true,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  refetchInterval,
  enabled,
}: {
  key: string[]
  find?: { [key: string]: any }
  sort?: { [key: string]: number }
  keepPreviousData?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
  refetchInterval?: number
  enabled?: boolean
}) {
  const organization = useAtomValue(aOrganization)
  const queryClient = useQueryClient()
  const searchQuery = useQuery({
    queryKey: key,
    queryFn: !find
      ? undefined
      : () =>
          fetchSearches({
            find: { ...find, 'organization._id': organization!._id },
            sort,
          }),
    // keepPreviousData,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchInterval,
    enabled: enabled && !!find,
  })
  return {
    isLoading:
      searchQuery.isLoading ||
      searchQuery.isFetching ||
      searchQuery.isRefetching,
    isError: searchQuery.isError,
    searches: searchQuery.data?.searches || [],
    refetch: searchQuery.refetch,
    updateSearchData: (
      updateFunc: (previousSearches: Search[]) => Search[]
    ) => {
      return queryClient.setQueryData(
        key,
        (previousData?: { success: boolean; searches: Search[] }) => {
          if (!previousData) return undefined
          return {
            ...previousData,
            searches: updateFunc(previousData.searches),
          }
        }
      )
    },
  }
}
